import { Pane, Paragraph } from 'evergreen-ui';

export default function Profile({ drawerInfo }) {
    return (
        <Pane
            alignItems="center"
            justifyContent="center"
            textAlign={'center'}
        >
            <img className={'m-img'} style={{ cursor: 'unset', filter: 'grayscale(0)' }} src={drawerInfo.img} alt={drawerInfo.name} />
            <hr/>
            <Paragraph textTransform={'capitalize'} fontSize={18} color={'black'}>Rütbə: {drawerInfo.title}</Paragraph>
            <Paragraph color={'black'}>Doğum tarixi: {drawerInfo.date}</Paragraph>
            <Paragraph color={'black'}>Hadisə: {drawerInfo.id.indexOf("_") === -1 ? "İkinci Qarabağ müharibəsi" : "2022-ci il sentyabrın 12-dən etibarən Ermənistan silahlı qüvvələrinin törətdiyi genişmiqyaslı təxribatların qarşısının alınması zamanı şəhid olan hərbi qulluqçu."}</Paragraph>
        </Pane>
    )
}